::selection {
  background: #222;
  color: #fff;
}

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

#page-nav {
  min-height: 3rem;
  margin-top: 5vh;
  z-index: 2;
  width: 100%;

  .container {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

#page-logo {
  text-decoration: none;
  height: 76%;
  margin-right: 1rem;
  margin-left: -.5rem;

  img {
    height: 100%;
    width: auto;
  }
}

main {
  
}

/* Hide for now */
#page-footer { display: none; }
/*
#page-footer {
  height: 15vh;
  display: flex;
  background: $color-dark;
  color: $color-light;
  font-size: 75%;
  padding-top: .5rem;
}
*/

@media screen and (max-width: $sm) {
  #page-nav {
    top: 0;
  }
}
