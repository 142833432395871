@property --btn-deg {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

@keyframes btnSpin {
  from { --btn-deg: 0deg; }
  to   { --btn-deg: 360deg; }
}

.button {
  padding: .5rem 1.2rem;
  color: #333;
  background: transparent;
  transition: transform 0.15s ease, box-shadow .1s ease, background-color .1s linear, border-width 0.06s cubic-bezier(0.5, 0, 0.3, 1);
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  text-decoration: none;
  transform: scale(1);
  will-change: transform;
  box-shadow: 0 1px 3px 0px rgb(0 0 0 / 1%);
  border-width: 2px;
  border-style: solid;
  border-color: #333;
  

  &:hover,
  &:focus {
    color: #0b794b;
    border-image: conic-gradient(rgb(12, 202, 129), rgb(110, 47, 226), rgb(12, 202, 129));
    border-image: conic-gradient(from var(--btn-deg), rgb(12, 202, 129), rgb(110, 47, 226), rgb(12, 202, 129));
    border-image-slice: 1;
    transform: scale(1.03);
    box-shadow: 0 4px 6px 4px rgb(0 0 0 / 6%);
    animation: btnSpin 1s linear 0s infinite forwards;
  }

  svg {
    display: inline-block;
    height: 1em;
    width: auto;

    &:first-child { margin-right: .5em; }
    &:last-child { margin-left: .5em; }
  }

  &.primary {
    background: $primary;

    &:hover,
    &:focus {
      background: lighten($primary, 8%);
    }
  }
}
