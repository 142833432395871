$sm: 560px;
$md: 1000px;
$lg: 1400px;

$font-body: 'Open Sans', sans-serif;
$font-header: 'Playfair Display', serif;
$size1: 2.4em;
$size2: 1.6em;
$size3: 1.4em;
$size4: 1.25em;
$size5: 1.1em;
$size6: 0.9em;

$primary: #c31656;
$color-dark: #2a2a2a;
$color-light: #999;
$color-text: #454545;
$color-header: $color-dark;

$black: hsl(0, 0%, 4%);
$black-bis: hsl(0, 0%, 7%);
$black-ter: hsl(0, 0%, 14%);
$grey-darker: hsl(0, 0%, 21%);
$grey-dark: hsl(0, 0%, 29%);
$grey: hsl(0, 0%, 48%);
$grey-light: hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);
$grey-lightest: hsl(0, 0%, 93%);
$white-ter: hsl(0, 0%, 96%);
$white-bis: hsl(0, 0%, 98%);
$white: hsl(0, 0%, 100%);
