/* Document
 * ========================================================================== */

/**
 * Add border box sizing in all browsers (opinionated).
 */

*,
::before,
::after {
  box-sizing: border-box;
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */

::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Use a 4-space tab width in all browsers (opinionated).
 * 4. Remove the grey highlight on links in iOS (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */

html {
  cursor: default; /* 1 */
  line-height: 1.5; /* 2 */
  -moz-tab-size: 4; /* 3 */
  tab-size: 4; /* 3 */
  -webkit-tap-highlight-color: transparent /* 4 */;
  -ms-text-size-adjust: 100%; /* 5 */
  -webkit-text-size-adjust: 100%; /* 5 */
  word-break: break-word; /* 6 */
}

/* Sections
 * ========================================================================== */

/**
 * Remove the margin in all browsers (opinionated).
 */

body {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/**
 * 1. Correct the inheritance of border color in Firefox.
 * 2. Add the correct box sizing in Firefox.
 * 3. Show the overflow in Edge 18- and IE.
 */

hr {
  color: inherit; /* 1 */
  height: 0; /* 2 */
  overflow: visible; /* 3 */
}

/**
 * Add the correct display in IE.
 */

main {
  display: block;
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */

nav ol,
nav ul {
  list-style: none;
  padding: 0;
}

/**
 * Prevent VoiceOver from ignoring list semantics in Safari (opinionated).
 */

nav li::before {
  content: "\200B";
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Prevent overflow of the container in all browsers (opinionated).
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
  overflow: auto; /* 3 */
  -ms-overflow-style: scrollbar; /* 3 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Remove the gray background on active links in IE 10.
 */

a {
  background-color: transparent;
}

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/*
 * Change the alignment on media elements in all browsers (opinionated).
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */

audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on iframes in all browsers (opinionated).
 */

iframe {
  border-style: none;
}

/**
 * Remove the border on images within links in IE 10-.
 */

img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */

svg:not([fill]) {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Tabular data
 * ========================================================================== */

/**
 * 1. Collapse border spacing in all browsers (opinionated).
 * 2. Correct table border color inheritance in all Chrome, Edge, and Safari.
 * 3. Remove text indentation from table contents in Chrome, Edge, and Safari.
 */

table {
  border-collapse: collapse; /* 1 */
  border-color: inherit; /* 2 */
  text-indent: 0; /* 3 */
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */

button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Correct the padding in Firefox.
 */

fieldset {
  border: 1px solid #a0a0a0; /* 1 */
  padding: 0.35em 0.75em 0.625em; /* 2 */
}

/**
 * Show the overflow in Edge 18- and IE.
 */

input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */

legend {
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction in all browsers (opinionated).
 */

textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
  resize: vertical; /* 3 */
  resize: block; /* 3 */
}

/**
 * Remove the padding in IE 10-.
 */

[type="checkbox"],
[type="radio"] {
  padding: 0;
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */

:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge 18- and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/**
 * Add the correct display in IE 9-.
 */

canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */

template {
  display: none;
}

/* User interaction
 * ========================================================================== */

/*
 * Remove the tapping delay in IE 10.
 */

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
}

/**
 * Add the correct display in IE 10-.
 */

[hidden] {
  display: none;
}

/* Accessibility
 * ========================================================================== */

/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */

[aria-busy="true"] {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */

[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */

[aria-disabled="true"],
[disabled] {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */

[aria-hidden="false"][hidden] {
  display: initial;
}

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

.container {
  max-width: 1400px;
  width: 96vw;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  margin-left: auto;
  margin-right: auto;
}
.container-fluid {
  width: 96vw;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
}
.d-mobile {
  display: none;
}
@media screen and (max-width: 560px) {
  .d-mobile {
    display: block;
  }
}
::-moz-selection {
  background: #222;
  color: #fff;
}
::selection {
  background: #222;
  color: #fff;
}
html, body {
  height: 100%;
}
body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#page-nav {
  min-height: 3rem;
  margin-top: 5vh;
  z-index: 2;
  width: 100%;
}
#page-nav .container {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#page-logo {
  text-decoration: none;
  height: 76%;
  margin-right: 1rem;
  margin-left: -0.5rem;
}
#page-logo img {
  height: 100%;
  width: auto;
}
/* Hide for now */
#page-footer {
  display: none;
}
/*
#page-footer {
  height: 15vh;
  display: flex;
  background: $color-dark;
  color: $color-light;
  font-size: 75%;
  padding-top: .5rem;
}
*/
@media screen and (max-width: 560px) {
  #page-nav {
    top: 0;
  }
}
html {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  cursor: inherit;
  color: #454545;
}
h1, .h1 {
  font-family: "Playfair Display", serif;
}
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: #2a2a2a;
  font-weight: 500;
}
h1, .h1 {
  font-size: 2.4em;
}
h2, .h2 {
  font-size: 1.6em;
}
h3, .h3 {
  font-size: 1.4em;
}
h4, .h4 {
  font-size: 1.25em;
}
h5, .h5 {
  font-size: 1.1em;
}
h6, .h6 {
  font-size: 0.9em;
  text-transform: uppercase;
}
.title {
  font-family: "Playfair Display", serif;
}
.subtitle {
  margin-top: -1.3rem;
}
@media screen and (max-width: 560px) {
  html {
    font-size: 17px;
  }
}
.text-black {
  color: #0a0a0a;
}
.text-black-bis {
  color: #121212;
}
.text-black-ter {
  color: #242424;
}
.text-grey-darker {
  color: #363636;
}
.text-grey-dark {
  color: #4a4a4a;
}
.text-grey {
  color: #7a7a7a;
}
.text-grey-light {
  color: #b5b5b5;
}
.text-grey-lighter {
  color: #dbdbdb;
}
.text-grey-lightest {
  color: #ededed;
}
.text-white-ter {
  color: whitesmoke;
}
.text-white-bis {
  color: #fafafa;
}
.text-white {
  color: white;
}
@property --btn-deg {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
@-webkit-keyframes btnSpin {
  from {
    --btn-deg: 0deg;
  }
  to {
    --btn-deg: 360deg;
  }
}
@keyframes btnSpin {
  from {
    --btn-deg: 0deg;
  }
  to {
    --btn-deg: 360deg;
  }
}
.button {
  padding: 0.5rem 1.2rem;
  color: #333;
  background: transparent;
  -webkit-transition: background-color 0.1s linear, border-width 0.06s cubic-bezier(0.5, 0, 0.3, 1), -webkit-transform 0.15s ease, -webkit-box-shadow 0.1s ease;
  transition: background-color 0.1s linear, border-width 0.06s cubic-bezier(0.5, 0, 0.3, 1), -webkit-transform 0.15s ease, -webkit-box-shadow 0.1s ease;
  transition: transform 0.15s ease, box-shadow 0.1s ease, background-color 0.1s linear, border-width 0.06s cubic-bezier(0.5, 0, 0.3, 1);
  transition: transform 0.15s ease, box-shadow 0.1s ease, background-color 0.1s linear, border-width 0.06s cubic-bezier(0.5, 0, 0.3, 1), -webkit-transform 0.15s ease, -webkit-box-shadow 0.1s ease;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  -webkit-transform: scale(1);
          transform: scale(1);
  will-change: transform;
  -webkit-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.01);
          box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.01);
  border-width: 2px;
  border-style: solid;
  border-color: #333;
}
.button:hover, .button:focus {
  color: #0b794b;
  -o-border-image: conic-gradient(#0cca81, #6e2fe2, #0cca81);
     border-image: conic-gradient(#0cca81, #6e2fe2, #0cca81);
  -o-border-image: conic-gradient(from var(--btn-deg), #0cca81, #6e2fe2, #0cca81);
     border-image: conic-gradient(from var(--btn-deg), #0cca81, #6e2fe2, #0cca81);
  border-image-slice: 1;
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  -webkit-box-shadow: 0 4px 6px 4px rgba(0, 0, 0, 0.06);
          box-shadow: 0 4px 6px 4px rgba(0, 0, 0, 0.06);
  -webkit-animation: btnSpin 1s linear 0s infinite forwards;
          animation: btnSpin 1s linear 0s infinite forwards;
}
.button svg {
  display: inline-block;
  height: 1em;
  width: auto;
}
.button svg:first-child {
  margin-right: 0.5em;
}
.button svg:last-child {
  margin-left: 0.5em;
}
.button.primary {
  background: #c31656;
}
.button.primary:hover, .button.primary:focus {
  background: #e51c67;
}
#home-hero {
  position: relative;
  margin-top: 10vw;
}
#home-hero .button {
  margin-right: 0.2rem;
}
#hero-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
@media screen and (max-width: 560px) {
  #home-hero {
    height: auto;
    padding-top: 7vh;
    padding-bottom: 4vh;
    background: rgba(255, 255, 255, 0.7);
  }
  #home-hero .button {
    margin-bottom: 0.5rem;
  }
}
/*# sourceMappingURL=index.8652701a.css.map */
