#home-hero {
  position: relative;
  margin-top: 10vw;

  .button {
    margin-right: .2rem;
  }
}

#hero-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media screen and (max-width: $sm) {
  #home-hero {
    height: auto;
    padding-top: 7vh;
    padding-bottom: 4vh;
    background: rgba(255,255,255,0.7);

    .button {
      margin-bottom: .5rem;
    }
  }
}
