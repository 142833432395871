html {
  font-family: $font-body;
  font-size: 20px;
  cursor: inherit; // Override sanitize.css cursor change
  color: $color-text;
}

h1, .h1 { font-family: $font-header; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: $color-header;
  font-weight: 500;
}

h1, .h1 { font-size: $size1; }
h2, .h2 { font-size: $size2; }
h3, .h3 { font-size: $size3; }
h4, .h4 { font-size: $size4; }
h5, .h5 { font-size: $size5; }
h6, .h6 { font-size: $size6; text-transform: uppercase; }

.title { font-family: $font-header; }
.subtitle { margin-top: -1.3rem; }

@media screen and (max-width: $sm) {
  html {
    font-size: 17px;
  }
}

.text-black { color: $black; }
.text-black-bis { color: $black-bis; }
.text-black-ter { color: $black-ter; }
.text-grey-darker { color: $grey-darker; }
.text-grey-dark { color: $grey-dark; }
.text-grey { color: $grey; }
.text-grey-light { color: $grey-light; }
.text-grey-lighter { color: $grey-lighter; }
.text-grey-lightest { color: $grey-lightest; }
.text-white-ter { color: $white-ter; }
.text-white-bis { color: $white-bis; }
.text-white { color: $white; }
