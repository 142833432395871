.container {
  max-width: 1400px;
  width: 96vw;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  margin-left: auto;
  margin-right: auto;
}

.container-fluid {
  width: 96vw;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
}

.d-mobile {
  display: none;
}

@media screen and (max-width: $sm) {
  .d-mobile {
    display: block;
  }
}
